<template>
  <main>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Nombre</span>
            <v-text-field
              v-model="requestClass.name"
              :error-messages="validationErrors.name"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Código</span>
            <v-text-field
              v-model="requestClass.code"
              :error-messages="validationErrors.code"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
import { storeRequestClass, updateRequestClass } from "@/services/humansRights";
import sweetalert from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";

export default {
  props: {
    mode: {
      type: String,
      default: "create",
    },
  },
  name: "newEvent.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      requestClass: {
        id: "",
        name: "",
        code: "",
      },
      eventId: "",
    };
  },
  validations() {
    return {
      requestClass: {
        name: { required },
        code: { required },
      },
    };
  },
  methods: {
    addAffected(affected) {
      this.requestClass.affecteds.push(affected);
    },
    removeAffected(affected) {
      const index = this.requestClass.affecteds.indexOf(affected);
      if (index !== -1 && affected["id"] !== undefined)
        this.requestClass.affecteds[index]["remove"] = true;
      if (index !== -1 && affected["id"] === undefined)
        this.requestClass.affecteds.splice(index, 1);
    },
    async save() {
      this.$v.requestClass.$touch();
      if (this.$v.requestClass.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          name: this.requestClass.name,
          code: this.requestClass.code,
        };

        console.log(this.requestClass);

        const storeEventResponse =
          this.mode === "edit"
            ? await updateRequestClass(this.requestClass.id, eventRequestData)
            : await storeRequestClass(eventRequestData);

        this.eventId = storeEventResponse.data.event_id;

        await sweetalert.fire(
          "Registro guardado correctamente",
          "",
          "success"
        );

        this.hideLoader();

        this.$router.push({
          name: "humansRights.listRequestClass",
        });
      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setEventData(eventData) {
      this.requestClass = eventData;
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
  },
  async created() {
    //const eventTypesResponse = await eventTypesList();
    //this.disharmonyTypes = eventTypesResponse.data
  },
  computed: {
    validationErrors() {
      return {
        name: [
          ...[
            !this.$v.requestClass.name.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        code: [
          ...[
            !this.$v.requestClass.code.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
};
</script>

<style scoped></style>
